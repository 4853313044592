import Vue from 'vue';
import VueLazyload from 'vue-lazyload';
import {
  Tab,
  Tabs,
  Swipe,
  SwipeItem,
  Tabbar,
  TabbarItem,
  Icon,
  Search,
  IndexBar,
  IndexAnchor,
  Cell,
  Empty,
  Sidebar,
  SidebarItem,
  TreeSelect,
  Image,
  Form,
  Field,
  Button,
  Checkbox,
  CheckboxGroup,
  Collapse,
  CollapseItem,
  Popup,
  Picker,
  Dialog,
  Toast,
  ActionSheet,
  Overlay,
  Loading,
  NumberKeyboard,
  Calendar,
  CellGroup,
  List,
  Rate,
  RadioGroup,
  Radio,
  Lazyload,
  DatetimePicker,
  DropdownMenu,
  DropdownItem,
  Popover,
} from 'vant';
//图片懒加载
Vue.use(Lazyload);

Vue.use(List);
//轮播图
Vue.use(Swipe);
Vue.use(SwipeItem);
//底部导航
Vue.use(Tabbar);
Vue.use(TabbarItem);
//icon图标
Vue.use(Icon);
//tab
Vue.use(Tab);
Vue.use(Tabs);
//搜索
Vue.use(Search);
//索引栏
Vue.use(IndexBar);
Vue.use(IndexAnchor);

Vue.use(Cell);
Vue.use(CellGroup);
//空状态
Vue.use(Empty);
//标签栏
Vue.use(Sidebar);
Vue.use(SidebarItem);
//分类栏
Vue.use(TreeSelect);
//图片
Vue.use(Image);
//表单
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
//复选框
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
//折叠面板
Vue.use(Collapse);
Vue.use(CollapseItem);

Vue.use(Popup);
Vue.use(Picker);

//弹出框
Vue.use(Dialog);

//轻提示
Vue.use(Toast);

//动作面板
Vue.use(ActionSheet);

// 遮罩层
Vue.use(Overlay);
//等待
Vue.use(Loading);

Vue.use(NumberKeyboard);

//日历
Vue.use(Calendar);

//评分
Vue.use(Rate);

//单选
Vue.use(RadioGroup);
Vue.use(Radio);

Vue.use(DatetimePicker);

//下拉菜单
Vue.use(DropdownMenu);
Vue.use(DropdownItem);

//气泡弹出框
Vue.use(Popover)

//图片懒加载
Vue.use(VueLazyload, {
  loading: require('./img/jiazai.svg'),
  lazyComponent: true,
});

//图片懒加载
Vue.use(Lazyload);

Vue.prototype.$Dialog = Dialog;
Vue.prototype.$Toast = Toast;
