import { JUMP } from './mutation-types'
export default {
  [JUMP]({ commit }, { menuName, _this, path, flag }) {
    if (menuName == '门诊挂号') {
      commit('JUMP_POST', {
        reg_type: '0',
        menuName,
        reg_class: '1',
        _this,
        path,
        flag: '0',
      })
    } else if (menuName == '专家挂号') {
      commit('JUMP_POST', {
        reg_type: '1',
        menuName,
        reg_class: '4',
        _this,
        path,
        flag,
      })
    } else if (menuName == '普通预约') {
      commit('JUMP_POST', {
        reg_type: '2',
        menuName,
        reg_class: '1',
        _this,
        path,
        flag,
      })
    } else if (menuName == '专家预约') {
      commit('JUMP_POST', {
        reg_type: '2',
        menuName,
        reg_class: '4',
        _this,
        path,
        flag,
      })
    } else {
      commit('JUMP_POST', { menuName, _this, path })
      // _this.$router.push(path);
    }
  },
}
