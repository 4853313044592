import { request } from './request'
//获取微信ticket
export function getAccessToken() {
  return request({
    url: '/weChat/user/getAccessToken',
    method: 'post',
  })
}
//获取微信签名
export function getSignature(data) {
  return request({
    url: '/weChat/user/getSignature',
    method: 'post',
    data,
  })
}
//保存流水号
export function saveSerialNumberPost(list) {
  return request({
    url: '/api/logFunTrade/addLogFunTrade',
    method: 'POST',
    data: list,
  })
}
//更新流水号
export function updateLogFunTrade(list) {
  return request({
    url: '/api/logFunTrade/updateLogFunTrade',
    method: 'POST',
    data: list,
  })
}
//菜单获取
export function getMenuInfo() {
  return request({
    url: '/weChat/menu/get_menu_info',
    method: 'POST',
  })
}
//用户绑卡
export function open(list) {
  return request({
    url: '/weChat/patient/tied_card',
    method: 'POST',
    data: list,
  })
}
//身份证查询档案信息
export function jd2hisPatientIdcard(list) {
  return request({
    url: '/api/patient/jd2his_q_patient',
    method: 'POST',
    data: list,
  })
}
//发卡建档
export function jd2hisCreateCard(list) {
  return request({
    url: '/api/createcard/jd2his_create_card',
    method: 'POST',
    data: list,
  })
}
//绑卡信息查询
export function cardBindingQuery(list) {
  return request({
    url: '/weChat/patient/tied_card_info_query',
    method: 'POST',
    data: list,
  })
}
//修改绑卡信息
export function updateTiedCardInfo(list) {
  return request({
    url: '/weChat/patient/update_tied_card_info',
    method: 'POST',
    data: list,
  })
}
//用户信息查询
export function user(list) {
  return request({
    url: '/weChat/patient/get_public_no_user_info',
    method: 'POST',
    data: list,
  })
}
//用户删除
export function userDelete(list) {
  return request({
    url: '/weChat/patient/del_card',
    method: 'POST',
    data: list,
  })
}
//科室查询(正式)
export function departmentQuery(list) {
  return request({
    url: '/api/register/jd2his_q_register_department_plan',
    method: 'POST',
    data: list,
  })
}
//号源查询
export function appointmentAssignedNumber(list) {
  return request({
    url: '/api/appointment/jd2his_q_appointment_doctor_assigned_number',
    method: 'POST',
    data: list,
  })
}
//医生排班查询
export function doctorPlan(list) {
  return request({
    url: '/api/register/jd2his_q_register_doctor',
    method: 'POST',
    data: list,
  })
}
//医生号源查询
export function registerAssignedNumber(list) {
  return request({
    url: '/api/register/jd2his_q_register_assigned_number',
    method: 'POST',
    data: list,
  })
}
//挂号预结算
export function registerPreSettlement(list) {
  return request({
    url: '/api/register/jd2his_register_pre_settlement',
    method: 'POST',
    data: list,
  })
}
//预约处理
export function jd2hisAppointmentProcess(list) {
  return request({
    url: '/api/appointment/jd2his_appointment_process',
    method: 'POST',
    data: list,
  })
}
//取消挂号
export function jd2hisAppointmentCancel(list) {
  return request({
    url: '/api/register/jd2his_appointment_cancel',
    method: 'POST',
    data: list,
  })
}
//挂号退款
export function refund(list) {
  return request({
    url: '/api/ulinkpay/refund',
    method: 'POST',
    data: list,
  })
}
//保存支付总订单表日志记录
export function saveTradeLog(list) {
  return request({
    url: '/api/log/saveTradeLog',
    method: 'POST',
    data: list,
  })
}
//挂号或预约查询
export function businessHistoryQuery(list) {
  return request({
    url: '/api/history/business_history_query',
    method: 'POST',
    data: list,
  })
}
//支付订单查询
export function payHistoryQuery(list) {
  return request({
    url: '/api/history/pay_history_query',
    method: 'POST',
    data: list,
  })
}
//清对应的session
export function clearSession() {
  return request({
    url: '/api/clear_session',
    method: 'POST',
  })
}
//挂号预约记录查询
export function businessRegisterQuery(list) {
  return request({
    url: '/api/history/business_register_query',
    method: 'POST',
    data: list,
  })
}
//检查单查询
export function checkQuery(list) {
  return request({
    url: '/api/report/jd2his_check_result_query',
    method: 'POST',
    data: list,
  })
}
//检验单查询
export function inspectQuery(list) {
  return request({
    url: '/api/report/jd2his_q_inspect_list',
    method: 'POST',
    data: list,
  })
}
//检验单详情查询
export function testResults(list) {
  return request({
    url: '/api/report/jd2his_q_inspect_result',
    method: 'POST',
    data: list,
  })
}
//身份证查询住院档案信息
export function inpatientIdcard(list) {
  return request({
    url: '/api/inpatient/jd2his_q_inpatient',
    method: 'POST',
    data: list,
  })
}
//住院费用查询
export function jd2hisInpatientCost(list) {
  return request({
    url: '/api/inpatient/jd2his_inpatient_cost',
    method: 'POST',
    data: list,
  })
}
//住院日清单查询
export function jd2hisInpatientDaylist(list) {
  return request({
    url: '/api/inpatient/jd2his_q_inpatient_daylist',
    method: 'POST',
    data: list,
  })
}
//调查问卷
export function feedback(list) {
  return request({
    url: '/weChat/questionnaire/questionnaire_templet_id_query',
    method: 'POST',
    data: list,
  })
}
//检查检验报告单查询
export function ResultQuery(list) {
  return request({
    url: '/api/report/jd2his_check_result_query',
    method: 'POST',
    data: list,
  })
}
//问卷提交
export function submitPost(list) {
  return request({
    url: '/weChat/questionnaire/questionnaire_commit',
    method: 'POST',
    data: list,
  })
}
//问卷id获取
export function questionnaireQuery() {
  return request({
    url: '/weChat/questionnaire/questionnaire_query',
    method: 'POST',
  })
}
//缴费信息
export function patientPayInfo(list) {
  return request({
    url: '/api/feespay/jd2his_q_pay_list',
    method: 'POST',
    data: list,
  })
}
//缴费明细
export function patientPayInfoDetail(list) {
  return request({
    url: '/api/feespay/jd2his_q_pay_detail',
    method: 'POST',
    data: list,
  })
}
//缴费结算
export function jd2hisFeesPreSettlement(list) {
  return request({
    url: '/api/feespay/jd2his_fees_settlement',
    method: 'POST',
    data: list,
  })
}
//门诊信息查询
export function outPatientFeeInfo(list) {
  return request({
    url: '/api/patient/jd2his_q_outPatient_fee_info',
    method: 'POST',
    data: list,
  })
}
//门诊信息明显查询
export function outPatientFeeInfoDetail(list) {
  return request({
    url: '/api/patient/jd2his_q_outPatient_fee_info_detail',
    method: 'POST',
    data: list,
  })
}
//新闻
export function news(list) {
  return request({
    url: '/api/news/news_query',
    method: 'POST',
    data: list,
  })
}
//排队叫号
export function jd2hisQueueQuery(list) {
  return request({
    url: '/api/patient/jd2his_queue_query',
    method: 'POST',
    data: list,
  })
}
//体检建卡
export function createHealthCard(list) {
  return request({
    url: '/api/health/jd2his_create_health_card',
    method: 'POST',
    data: list,
  })
}
//是否已建卡
export function checkHealthCard(list) {
  return request({
    url: '/api/health/jd2his_q_health_card',
    method: 'POST',
    data: list,
  })
}
//合约单位信息
export function constractUnitInfo(list) {
  return request({
    url: '/api/health/jd2his_q_constract_unit_info',
    method: 'POST',
    data: list,
  })
}
//获取体检人员信息
export function healthPatientInfo(list) {
  return request({
    url: '/api/health/jd2his_q_health_patient_info',
    method: 'POST',
    data: list,
  })
}
//获取体检缴费列表
export function healthFeeInfo(list) {
  return request({
    url: '/api/health/jd2his_q_health_fee_info',
    method: 'POST',
    data: list,
  })
}
//获取体检报告单列表
export function healthReportList(list) {
  return request({
    url: '/api/health/jd2his_q_health_report_list',
    method: 'POST',
    data: list,
  })
}
//获取体检报告单PDF地址
export function reportPdfUrl(list) {
  return request({
    url: '/api/health/jd2his_q_report_pdf_url',
    method: 'POST',
    data: list,
  })
}
//获取体检报告单PDF信息
export function reportPdfInfo(list) {
  return request({
    url: '/api/health/jd2his_q_health_report_detail',
    method: 'POST',
    data: list,
  })
}
