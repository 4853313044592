import {
  user,
  clearSession,
  cardBindingQuery,
  saveSerialNumberPost,
  businessRegisterQuery,
} from '@/network/service'
import { Toast } from 'vant'
import {
  USER_POST,
  JUMP_POST,
  INFORMATION_SERVICE,
  HIDSHOW_TRIGGER,
} from './mutation-types'
import { close } from '../common/utils'
export default {
  //获取用户信息
  async [USER_POST](state, { _this, name } = { _this: null, name: null }) {
    let open_id = state.open_id.open_id
    let res = await cardBindingQuery({
      open_id,
    })
    if (res.code == '0') {
      state.cardList = res.data.sort((item, list) => {
        return list.updateTime > item.updateTime ? 1 : -1
      })
      //获取头像
      let head = await user({
        open_id,
      })
      if (head.code == '0') {
        state.headPortrait = head.data.headimgurl
        state.head = head.data
        //判断是否有用户登录
        if (state.cardList.length != 0) {
          console.log(state.cardList)
          state.phoneState = state.cardList[0].phone
          state.med_id = state.cardList[0].cardNo
          //获取名字
          state.nameState = state.cardList[0].name
          if (name == 'login') {
            _this.$router.push('/service-scheme-two')
          } else if (_this != null) {
            _this.$nextTick(() => {
              _this.$refs.scroll.refresh()
            })
          }
        } else {
          if (name == 'delete') {
            let data = _this.$router.options.routes.find(
              item => item.redirect != undefined
            )
            _this.$router.push(data.redirect)
          }
          state.headPortrait = '#icon-weidenglutouxiang'
          state.nameState = '未登录'
          state.phoneState = '***********'
        }
      } else {
        Toast('获取信息失败')
      }
    } else {
      Toast('获取信息失败')
    }
  },
  //预约和挂号信息查询接口
  async [INFORMATION_SERVICE](state, { name, _this, type }) {
    //重新计算高度
    _this.$nextTick(() => {
      _this.$refs.scroll.refresh()
    })
    function fun_date(aa) {
      let date1 = new Date(),
        time1 =
          date1.getFullYear() +
          '-' +
          (date1.getMonth() + 1) +
          '-' +
          date1.getDate() //time1表示当前时间
      let date2 = new Date(date1)
      date2.setDate(date1.getDate() + aa)
      let time2 =
        date2.getFullYear() +
        '-' +
        (date2.getMonth() + 1).toString().padStart(2, 0) +
        '-' +
        date2
          .getDate()
          .toString()
          .padStart(2, 0)
      return [time1, time2]
    }
    let funDate = fun_date(7)
    // let y = new Date().getFullYear()
    // let m = new Date().getMonth() + 1;
    // m = m < 10 ? '0' + m : m;
    // let d = new Date().getDate() + 7;
    // d = d < 10 ? '0' + d : d;
    // let enddate = y + '-' + m + '-' + d
    //预约信息查询
    let res = await businessRegisterQuery({
      card_no: state.cardList[0].cardNo,
      business_type: type,
      begin_date: fun_date(-30)[1],
      end_date: funDate[1],
      page_no: state[name].page,
      page_size: state.pageSet,
    })
    if (res == undefined) {
      _this.loading = false
      _this.error = true
    } else if (res.code == '0') {
      console.log(res.data.content)
      if (state[name].page == 1 && res.data.content.length == 0) {
        //第一次加载没数据
        _this.loading = false
      } else if (
        state[name].page == 1 &&
        res.data.content.length < state.pageSet
      ) {
        //第一次加载数少于10个
        state[name].list = [...res.data.content]
        state[name].list.sort((a, b) => a.orderFlag - b.orderFlag)
        state[name].list.forEach(el => {
          if (el.orderFlag == 0) {
            if (
              Number(el.diagnosisTime.slice(6, 8)) == funDate[0].slice(6, 8) &&
              Number(el.diagnosisTime.slice(9, 11) < funDate[0].slice(9, 11))
            ) {
              el.orderFlag == 2
            } else if (
              Number(el.diagnosisTime.slice(6, 8)) == funDate[0].slice(6, 8)
            ) {
              el.orderFlag == 2
            }
          }
        })
        // 加载状态结束
        _this.loading = false
      } else {
        _this.loading = true
        //添加信息
        state[name].list.push(...res.data.content)
        state[name].list.sort((a, b) => a.orderFlag - b.orderFlag)
        state[name].list.forEach(el => {
          if (el.orderFlag == 0) {
            if (
              Number(el.diagnosisTime.slice(6, 8)) == funDate[0].slice(6, 8) &&
              Number(el.diagnosisTime.slice(9, 11) < funDate[0].slice(9, 11))
            ) {
              el.orderFlag == 2
            } else if (
              Number(el.diagnosisTime.slice(6, 8)) == funDate[0].slice(6, 8)
            ) {
              el.orderFlag == 2
            }
          }
        })
        //上拉加载一页
        state[name].page += 1
        _this.loading = false
        //上拉加载更多
        _this.$refs.scroll.finishPullUp()
        if (res.data.content.length == 0) {
          state.valve = false
          _this.finished = true
        }
      }
      //卡号过滤
      state[name].list = state[name].list.filter(
        item => item.cardNo == state.cardList[0].cardNo
      )
      //重新计算高度
      _this.$nextTick(() => {
        _this.$refs.scroll.refresh()
      })
    }
  },
  //跳转请求流水号
  async [JUMP_POST](
    state,
    { reg_type, menuName, reg_class, _this, path, flag, id, title, stat }
  ) {
    //清对应的session
    await clearSession()
    state.reg_type = reg_type
    state.menuName = menuName
    state.reg_class = reg_class
    if (state.cardList.length == 0) {
      _this.$router.push('/login')
    } else {
      //请求流水号
      let res = await saveSerialNumberPost({
        name: state.cardList[0].name,
        idno: state.cardList[0].idNo,
        card_no: state.cardList[0].cardNo,
        card_type: state.cardList[0].cardType,
        menuName,
      })
      if (res.code == '0') {
        //流水号
        console.log(menuName, stat)
        state.seq = res.data.processSeq
        state.adopt = true
        if (menuName == '缴费' && stat == 1) {
          _this.$toast('维护中')
        } else if (menuName == '挂号' && stat == 1) {
          _this.$toast('维护中')
        } else {
          if (close()) {
            _this.$dialog
              .alert({
                title: '重要通知',
                message: `廊坊市妇幼保健院由于财务年底结算，<span style='color:red'>2021年12月29日20:00一一2022年1月1日8:00期间</span>，暂停微信公众号网上预约挂号，门诊收费处暂停使用银行卡、微信、支付宝、自助机缴费等功能,请各位就诊患者及家属在此时间段<span style='color:red'>携带现金现场挂号就诊和缴费</span>。给您带来不便，敬请谅解，感谢您的配合!`,
                messageAlign: 'left',
                allowHtml: true,
              })
              .then(() => {
                _this.$dialog.close()
              })
          } else {
            _this.$router.push({ path, query: { flag, id, title } })
          }
        }
      }
    }
  },
  //安卓系统键盘抬起tabbar问题
  [HIDSHOW_TRIGGER](state, data) {
    state.hidshow = data
  },
}
