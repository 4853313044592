<template>
  <div id="app">
    <transition :name="transitionName">
      <keep-alive
        exclude="choiceDoctor,handle,appointmentCancellation,appointmentRecord,paymentRecordQuery,login,registrationRecord,detailsHospitalization,dailyList,hospitalizationExpenses,inspectionSheet,questionnaire,pay,paymentDetails,complaint,hospitalizationPrepayment,serviceSchemeTwo,queue,department,news,user-data,appointmentSuccessful,drugs,healthEducation,healthy,checkList,expenditureDetail,outpatientExpenditure,excessivePage,physicalPay,physicalReport,physicalPDF"
      >
        <img
          v-if="show"
          @touchmove.prevent
          class="imgs"
          src="@/static/img/home/home.webp"
          alt=""
        />
        <router-view v-else />
      </keep-alive>
    </transition>
    <VantTabbar v-show="$store.state.hidshow" />
  </div>
</template>

<script>
import VantTabbar from './components/content/tabbar/Tabbar'
export default {
  data() {
    return {
      transitionName: '',
      show: true,
    }
  },
  components: {
    VantTabbar,
  },
  mounted() {
    setTimeout(() => {
      this.show = false
    }, 1000)
  },
  watch: {
    $route(to, from) {
      if (
        to.meta.level !== from.meta.level &&
        to.meta.level !== from.meta.level &&
        to.meta.level !== from.meta.level &&
        to.meta.level !== from.meta.level
      ) {
        //如果to索引大于from索引,判断为前进状态,反之则为后退状态
        if (to.meta.level < from.meta.level) {
          //设置动画名称
          this.transitionName = 'slide-left'
        } else {
          this.transitionName = 'slide-right'
        }
      } else {
        this.transitionName = 'none-left'
      }
    },
  },
}
</script>

<style lang="less" scoped>
/* 初始化样式 */
@import './static/app.css';
@import './static/icon.css';
@import './assets/css/variable.less';

/*
动画样式
will-change: transform优化渲染速度
*/
.imgs {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  width: 100vw;
}
#app {
  height: 100vh;
  overflow: hidden;
  background-color: @backgroundColor;
}
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  width: 100vw;
  height: 100vh;
  transition: all 0.3s ease;
}
.slide-right-enter {
  transform: translateX(100%);
}
.slide-right-leave-to {
  display: none;
  transform: translate3d(100%);
}
.slide-left-enter {
  transform: translateX(-100%);
}
.slide-left-leave-to {
  display: none;
  transform: translate3d(-100%);
}
.none-right-enter-active,
.none-right-leave-active,
.none-left-enter-active,
.none-left-leave-active {
  overflow: hidden;
  height: 100vh;
}
</style>
