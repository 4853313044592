import axios from 'axios';
import Qs from 'qs';
import store from '../store';
import vue from 'vue';
export function request(config) {
  //1.创建axios实例
  const instance = axios.create({
    baseURL:
      process.env.NODE_ENV == 'development'
        ? 'http://121.196.22.202'
        : '',
    // baseURL: process.env.VUE_APP_MOCK != 'true' ? 'http://47.97.189.165' : '',
    timeout: 30000,
  });
  //1.请求拦截,interceptors(拦截).request(请求).use(使用)
  instance.interceptors.request.use(
    //成功的
    res => {
      vue.prototype.$toast.loading({
        duration: 0, // 持续展示 toast,
        message: '加载中...',
        forbidClick: true,
      });
      //添加userInfo数据
      if (config.method.toUpperCase() == 'POST') {
        if (store.state.cardList[0] != undefined && res.data != undefined) {
          let userInfo = {
            name: store.state.cardList[0].name,
            card_no: store.state.cardList[0].cardNo,
            card_type: store.state.cardList[0].cardType,
            idcard_card_no: store.state.cardList[0].idNo,
          };
          res.data = Qs.stringify(
            Object.assign(res.data, {
              userInfo: JSON.stringify(userInfo),
            })
          );
          //放行请求。拦截下来需要return出去，要不然拿不到数据
          return res;
        } else {
          res.data = Qs.stringify(res.data);
          return res;
        }
      }
    },
    //失败的
    err => console.log(err)
  );
  //2.响应拦截 response(响应)
  instance.interceptors.response.use(
    //成功的
    res => {
      vue.prototype.$toast.clear();
      //拦截下来需要return出去，要不然拿不到数据
      return res.data;
    },
    //失败的
    err => {
      if (err.config.url == "/api/ulinkpay/loopQuery") {
        return
      } else {
        vue.prototype.$toast.fail('网络异常');
      }
      // console.log(err.request,err.config)
    }
  );
  //发送真正的网络请求
  return instance(config);
}
