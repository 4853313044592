<template>
  <div>
    <svg
      class="icon"
      aria-hidden="true"
      :style="{height: size,width: size}"
    >
      <use
        class="icon-u"
        :xlink:href="href"
      ></use>
    </svg>
  </div>
</template>

<script>
export default {
  props: ['href', 'size'],
}
</script>

<style>
</style>