import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import axios from 'axios'
import Cookies from 'js-cookie'
import store from './store'

import svgCom from './components/content/svgCom/svgCom.vue' //引入组件
Vue.component('svg-com', svgCom) //注册组件(图标)

import 'pdfh5/css/pdfh5.css'

//vant框架
import './static/vant'

//iconfont图标
import './static/icon/iconfont.css'
import './static/icon/iconfont.js'

//引入base64解码
let Base64 = require('js-base64').Base64

//vue原型上添加
Vue.prototype.$Cookies = Cookies
Vue.prototype.$axios = axios

//下单入参
Vue.prototype.$urlString = 'http://127.0.0.1:8220/payv1'
// Vue.prototype.$urlString = 'http://172.16.19.77:8220/payv1'
// Vue.prototype.$mchNo = 'jandar'
// Vue.prototype.$appKey = '177146cb8f80001'
// Vue.prototype.$appSecret = '4fdb3405f7cc4554bd76ebd306d33fc5'

//将base64解码挂到vue原型
Vue.prototype.$Base64 = Base64

Vue.prototype.$stores = store
//创建实例给Vue原型下的$bus
Vue.prototype.$bus = new Vue()
//控制刷新状态
Vue.prototype.$valve = false
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
