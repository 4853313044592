//用户信息请求
export const USER_POST = 'USER_POST';
//预约和挂号信息查询接口
export const INFORMATION_SERVICE = 'INFORMATION_SERVICE';
//保存流水号
export const SAVE_SERIAL_NUMBER = 'SAVE_SERIAL_NUMBER';
//住院信息
export const INPATIENT_INFORMATION = 'INPATIENT_INFORMATION';
//挂号预约请求
export const JUMP_POST = 'JUMP_POST';
//跳转
export const JUMP = 'JUMP';
//安卓键盘tabbar控制
export const HIDSHOW_TRIGGER = 'HIDSHOW_TRIGGER';
