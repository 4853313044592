// 防抖
// export function debounce(fn, time, state) {
//   let t = null;
//   return function() {
//     let _this = this,
//       args = arguments;
//     if (t) setInterval(t);
//     //输入直接执行
//     if (state) {
//       let exec = !t;
//       t = setTimeout(() => {
//         t = null;
//       }, time);
//       if (exec) {
//         fn.apply(_this, args);
//       }
//     } else {
//       //输入时间间隙大于time时执行
//       t = setTimeout(() => {
//         fn.apply(_this, args);
//       }, time);
//     }
//   };
// }
import Vue from 'vue'

// 获取url中的参数 history模式下
export function formatUrlParams() {
  let url = window.location.href
  let obj = {}
  if (url.indexOf('?') !== -1) {
    let startIndex = url.indexOf('?') + 1
    let str
    if (url.indexOf('#') !== -1) {
      let endIndex = url.indexOf('#')
      str = url.substring(startIndex, endIndex)
    } else {
      str = url.substring(startIndex)
    }
    let strs = str.split('&')
    for (let i = 0; i < strs.length; i++) {
      obj[strs[i].split('=')[0]] = strs[i].split('=')[1]
    }
    return obj
  }
}

//节流
export const Throttle = (fn, t) => {
  let last
  let timer
  let interval = t || 500
  return function() {
    let args = arguments
    let now = +new Date()
    if (last && now - last < interval) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        last = now
        fn.apply(this, args)
      }, interval)
    } else {
      last = now
      fn.apply(this, args)
    }
  }
}

//防抖
export const Debounce = (fn, t) => {
  let delay = t || 500
  let timer
  return function() {
    Vue.prototype.$toast.loading({
      duration: 0, // 持续展示 toast,
      message: '加载中...',
      forbidClick: true,
    })
    let args = arguments
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      timer = null
      fn.apply(this, args)
    }, delay)
  }
}

//获取一周时间
export function getWeek(day) {
  let today = new Date()
  let targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day
  today.setTime(targetday_milliseconds)
  //年
  let tYear = today.getFullYear()
  //月
  let tMonth = today.getMonth()
  //日
  let tDate = today.getDate()
  tMonth = doHandleMonth(tMonth + 1)
  tDate = doHandleMonth(tDate)

  function doHandleMonth(month) {
    let m = month
    if (month.toString().length == 1) {
      m = '0' + month
    }
    return m
  }
  return tYear + '-' + tMonth + '-' + tDate
}

//判断星期 2000-10-10
export function getsWeek(time) {
  let dateArray = time.split('-')
  let date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2])
  return '周' + '日一二三四五六'.charAt(date.getDay())
}

//获取当前时间
export function times(month = false) {
  let date = new Date()
  let Y = date.getFullYear() + '-'
  let M = month
    ? (date.getMonth() + 1 < 10 ? date.getMonth() + 1 : date.getMonth() + 1) +
      '-'
    : (date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1) + '-'
  let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  let m =
    (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
  let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return Y + M + D + h + m + s
}

//标准时间转换
export function standard(time) {
  //时间转换
  let date = new Date(time)
  let y = date.getFullYear()
  let m = date.getMonth() + 1
  m = m < 10 ? '0' + m : m
  let d = date.getDate()
  d = d < 10 ? '0' + d : d
  return y + '-' + m + '-' + d
}

//新闻请求参数时间
export function newsdate() {
  let now = new Date()
  let year = now.getFullYear()
  let startmonth = now.getMonth()
  let day = now.getDate()
  let begintime
  let endmonth = startmonth + 1
  let endtime
  if (startmonth == 0) {
    startmonth = 12
    begintime =
      (year - 1).toString() +
      '-' +
      startmonth.toString().padStart(2, 0) +
      '-' +
      day.toString().padStart(2, 0)
    endtime =
      year.toString() +
      '-' +
      endmonth.toString().padStart(2, 0) +
      '-' +
      day.toString().padStart(2, 0)
  } else {
    begintime =
      year.toString() +
      '-' +
      startmonth.toString().padStart(2, 0) +
      '-' +
      day.toString().padStart(2, 0)
    endtime =
      year.toString() +
      '-' +
      endmonth.toString().padStart(2, 0) +
      '-' +
      day.toString().padStart(2, 0)
  }
  return [begintime, endtime]
}

export function close() {
  let closer = false
  let date = new Date()
  let Y = date.getFullYear() + '-'
  let M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
  let D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  let h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  if (Y + M + D == '2021-12-29' && h >= 20) {
    closer = true
  } else if (Y + M + D == '2021-12-30' || Y + M + D == '2021-12-31') {
    closer = true
  } else if (Y + M + D == '2022-01-01' && h < 8) {
    closer = true
  }
  return closer
}

//获取当前时间
export function getTimeNoSecond() {
  let date = new Date()
  let M =
    (date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1)
      : date.getMonth() + 1) + '-'
  let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
  let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
  let m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  return M + D + h + m
}

//获取当前时间的前面或后面的第n天
export function fun_date(aa) {
  let date1 = new Date()
  let time1 =
    date1.getFullYear() + '-' + (date1.getMonth() + 1) + '-' + date1.getDate() //time1表示当前时间
  let date2 = new Date()
  date2.setDate(date1.getDate() + aa)
  let time2 =
    date2.getFullYear() +
    '-' +
    (date2.getMonth() + 1).toString().padStart(2, 0) +
    '-' +
    date2
      .getDate()
      .toString()
      .padStart(2, 0)
  return [time1, time2]
}

export function insertStr(source, start, newStr) {
  return source.slice(0, start) + newStr + source.slice(start)
}

export function getNow() {
  let date = new Date()
  let Y = date.getFullYear() + '-'
  let M = (date.getMonth() + 1).toString().padStart(2, 0) + '-'
  let D = date
    .getDate()
    .toString()
    .padStart(2, 0)
  return Y + M + D
}
