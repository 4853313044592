<template>
  <div :class="$route.meta.showTab? 'vant-tabbar' : 'tabbar' ">
    <div
      :class="$route.meta.showTab?'vant-tab':'tab'"
      v-for="item in tabbars"
      :key="item.name"
      @click="tab(item.name)"
    >
      <!-- 图标 -->
      <svg
        class="icon"
        aria-hidden="true"
      >
        <use :xlink:href='item.name == active?item.icons:item.icon'></use>
      </svg>
      <span :class="item.name == active?'textColor':''">{{item.title}}</span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabbars: [{
        name: this.$router.options.routes[0].redirect,
        title: "主页",
        icon: '#icon-fenleixuanzhong',
        icons: '#icon-fenleixuanzhong-fuyou'
      }, {
        name: "/user",
        title: "我的",
        icon: '#icon-wodexuanzhong',
        icons: '#icon-wodexuanzhong-fuyou'
      }, {
        name: "/information",
        title: "医院",
        icon: '#icon-wodexuanzhong1',
        icons: '#icon-wodexuanzhong1-fuyou'
      }]
    };
  },
  computed: {
    //当前激活路由
    active () {
      return this.$route.path
    }
  },
  methods: {
    //底部导航
    tab (path) {
      this.$router.push(path);
    },
  }
}
</script>

<style lang="less" scoped>
.textColor {
  color: #f76391;
}
.tabbar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 3.75rem;
  background-color: rgb(255, 255, 255);
  transform: translateY(5rem);
  transition-duration: 0.3s;
}
.tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 0.5rem;
  height: 100%;
  flex: 0.25;
  .icon {
    font-size: 1.5rem;
    margin: 0 auto;
  }
  span {
    font-size: 0.75rem;
    padding-bottom: 0.5rem;
  }
}
.vant-tabbar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 3.75rem;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  transform: translateY(0rem);
  transition-duration: 0.3s;
  z-index: 1;
  .vant-tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 0.5rem;
    height: 100%;
    flex: 0.25;
    .icon {
      font-size: 1.5rem;
      margin: 0 auto;
    }
    span {
      font-size: 0.75rem;
      padding-bottom: 0.5rem;
    }
  }
}
</style>